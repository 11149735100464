<template>
  <div>
    <div v-if="products.length > 0" class="d-flex-column align-center main">
      <div class="heading">
        <h3 class="no-margin head">Purchase</h3>
      </div>
      <el-card>
        <div class="mw-400">
          <div class="main">
            <!-- <div v-for="type in types" :key="type" class="m-0"> -->
            <!-- <div :key="product._id" v-for="product in localPriceItem.pricing[type].saleableItems">
              {{ product }}  v-for="product in localPriceItem.pricing[type].saleableItems"
            </div> -->

            <AddonItem
              @addonMandatory="hideSkip = true"
              v-for="product of products"
              :key="product._id"
              :product="product"
              :requireSizes="product.requireSizes"
              :id="product._id"
              :title="product.name"
              :price="product.price"
              :subtitle="product.description"
              :qty.sync="product.qty"
              :notes.sync="product.notes"
              :img="
                typeof product.url === 'string'
                  ? product.url
                  : Array.isArray(product.url)
                  ? product.url[0]
                  : null
              "
              :mandatory="product.mandatory"
              :seasonPass="product.seasonPass"
              :seasonPassFrom="product.seasonPassFrom"
              :seasonPassTo="product.seasonPassTo"
              :current="current"
              class="addon"
            />
          </div>
        </div>
      </el-card>

      <el-card class="total-card">
        <div class="d-flex total-card-inner">
          <div>
            <h4 class="no-margin">Total</h4>
          </div>
          <div class="flex-last">
            <h3 class="no-margin">{{ subtotal }}</h3>
          </div>
        </div>
      </el-card>

      <div class="align-text-center">
        <el-button type="primary" class="full-width mw-400" @click="submitForm"
          >Add Items</el-button
        >
      </div>
      <div v-if="!hideSkip" class="align-text-center" id="skipButton">
        <el-button class="full-width mw-400 button-invisible" @click="skipForm">Skip</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { cloneDeep } from "lodash";
import AddonItem from "@/components/addon-item/AddonItem.vue";
import { formatMoney } from "@/utils/money";
import { endpointParams } from "@/utils/constants/api";
import { regoAddonSubtotal } from "@/utils/registration/addon";
import msg from "@/utils/constants/msg";

export default {
  name: "ChooseAddon",
  components: {
    AddonItem,
  },

  computed: {
    ...mapGetters("registration", ["current", "completed", "getAvailableProductsRealConcat"]),

    subtotal() {
      return formatMoney(regoAddonSubtotal({ priceItem: this.localPriceItem }));
    },
    types() {
      // eslint-disable-next-line prefer-const
      let types = [];
      Object.entries(this.localPriceItem.pricing).forEach(
        // eslint-disable-next-line no-unused-vars
        ([category, item]) => {
          types.push(category);
        }
      );
      return types;
    },
    nationalId() {
      return this.current.entity.orgtree.national ? this.current.entity.orgtree.national._id : 32;
    },
  },
  created() {
    // Set Current Price Item
    this.localPriceItem = cloneDeep(this.current.priceItem);
    // Skip if no addons
    if (this.getAvailableProductsRealConcat.length === 0) {
      if (
        this.current.pendingVerification &&
        this.nationalId === 32 &&
        this.current.entity.entityType !== "program"
      ) {
        this.$router.push({ name: "verificationupload" }).catch(() => {});
      } else {
        this.submitRegoAndNext();
      }
    } else {
      /* ----- START POPULATE ADDON ITEMS  ---- */
      // eslint-disable-next-line prefer-const
      let types = [];
      Object.entries(this.localPriceItem.pricing).forEach(
        // eslint-disable-next-line no-unused-vars
        ([category, item]) => {
          types.push(category);
        }
      );
      // mutating this.localPriceItem using reflection. Pretty bad way to do it

      types.forEach((type) => {
        const current = this.localPriceItem.pricing[type];
        if (current && current.saleableItems) {
          current.saleableItems.forEach((item) => {
            if (!item.qty) this.$set(item, "qty", 0);
            if (!item.notes) this.$set(item, "notes", "");
          });
        }
      });
      /* ----- END POPULATE ADDON ITEMS  ---- */

      /* ---- CHECK PRODUCTS ---- */
      const products = [];
      if (!this.localPriceItem.pricing || this.localPriceItem.pricing.length < 1) return products;

      for (const type of types) {
        if (
          !this.localPriceItem.pricing[type].saleableItems ||
          this.localPriceItem.pricing[type].saleableItems.length < 1
        )
          continue;
        for (const product of this.localPriceItem.pricing[type].saleableItems) {
          if (this.checkExistingSeasonPasses(product)) {
            // console.log(`failed seasonpasses ${product.name}`);
            continue;
          }
          if (!this.checkProductEntity(product)) {
            // console.log(`failed product entity ${product.name}`);
            continue;
          }

          if (!this.checkCompletedReg(product, this.current)) {
            // console.log(`failed completed reg ${product.name}`);
            continue;
          }

          products.push(product);
        }
      }
      if (products.length === 0) {
        this.skipForm();
      }
      this.products = products;
    }
  },
  data() {
    return {
      products: [],
      noProductsChecked: false,
      hideSkip: false,
      formatMoney,
      endpointParams,
      localPriceItem: {},
    };
  },
  methods: {
    checkExistingSeasonPasses(product) {
      if (
        this.current.profile &&
        this.current.profile.seasonPasses &&
        this.current.profile.seasonPasses.length > 0
      ) {
        if (this.current.profile.seasonPasses.includes(product._id)) return true;
        else {
          // Get the current Date
          const currentDate = new Date();
          const timestampDate = currentDate.getTime();
          if (
            product.seasonPassFrom &&
            product.seasonPassTo &&
            !(timestampDate >= product.seasonPassFrom && timestampDate <= product.seasonPassTo)
          )
            return true;
          else return false;
        }
      }
    },
    checkCompletedReg(product, current) {
      let flag = true;
      if (!this.completed) return flag;
      for (const rego of this.completed) {
        if (rego.id !== current.id) continue;
        // check season passes
        if (rego.profile && rego.profile.seasonPasses && rego.profile.seasonPasses.length > 0) {
          if (rego.profile.seasonPasses.includes(product._id)) {
            console.log("profile already has this season pass");
            flag = false;
          }
        }

        // check product ids
        if (rego.priceItem && rego.priceItem.pricing) {
          // loop thru all the types
          for (const typ of ["national", "state", "region", "association", "competition", "club"]) {
            if (rego.priceItem.pricing[typ] && rego.priceItem.pricing[typ].saleableItems) {
              for (const item of rego.priceItem.pricing[typ].saleableItems) {
                if (item.seasonPass && item._id === product._id) {
                  flag = false;
                }
              }
            }
          }
        }
      }
      return flag;
    },
    checkProductEntity(product) {
      // typical data shape

      const entity = this.current.entity;

      // check the product applies to this entity
      let flag = true;
      if (
        product.meta &&
        product.meta.entityType &&
        product.meta.entityIds &&
        product.meta.entityIds.length > 0
      ) {
        // does any of the org tree match

        for (const typ of ["national", "state", "region", "association", "club"]) {
          if (product.meta && product.meta.entityType === typ) {
            // need to check the entity includes this id

            if (!entity.orgtree[typ]) continue;
            flag = false;
            if (product.meta.entityIds.includes(entity.orgtree[typ]._id)) flag = true;
          }
        }

        // loop thru ids and check
      }
      return flag;
    },
    ...mapMutations("root", ["LOADING"]),
    ...mapActions("views", ["triggerEvent"]),
    ...mapActions("registration", ["submit", "updateCurrent"]),
    ...mapMutations("views", ["PUSH_NOTIFICATION"]),

    submitForm() {
      let throwError = false;
      let seasonPassesSet = new Set([]);
      if (this.current.profile && this.current.profile.seasonPasses)
        seasonPassesSet = new Set(this.current.profile.seasonPasses);

      this.types.forEach((type) => {
        this.localPriceItem.pricing[type].saleableItems.forEach((product) => {
          if (product.requireSizes && product.qty > 0 && product.notes === "") {
            throwError = true;
          }
          if (product.seasonPass) {
            seasonPassesSet.add(product._id);
          }
        });
      });
      if (throwError) {
        this.PUSH_NOTIFICATION({
          msg: msg.error.sizeValidationError,
          route: this.$route.name,
          type: "warning",
        });
        window.scrollTo(0, 0);
      } else {
        const seasonPassesUpdate = Array.from(seasonPassesSet);
        const currentProfile = { ...this.current.profile };
        currentProfile.seasonPasses = seasonPassesUpdate;
        this.updateCurrent({ priceItem: this.localPriceItem, profile: currentProfile });
        this.submitRegoAndNext();
      }
    },
    skipForm() {
      this.submitRegoAndNext();
    },
    async submitRegoAndNext() {
      if (
        this.current.verification.pending &&
        this.nationalId === 32 &&
        this.current.entity.entityType !== "program"
      ) {
        this.$router.push({ name: "verificationupload" }).catch(() => {});
      } else {
        await this.submit();
        this.$router.push({ name: "regocart" }).catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  text-align: left;
}

.addon:not(:last-child) {
  border-bottom: 1px solid lightgrey;
}

.total-card {
  width: 100vw;

  .total-card-inner {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
